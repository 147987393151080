<template>
    <div class="fjs-container">
        <form class="fjs-form">
            <div class="fjs-vertical-layout fjs-children cds--grid cds--grid--condensed">
                <slot />
            </div>
        </form>
    </div>
</template>

