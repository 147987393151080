import { computed, defineProps, } from 'vue'

export default {
    oneWayProps: (props) => {
        const propsCopied = {}
        for (const key of Object.keys(props)) {
            const value = props[key]
            if (Array.isArray(value)) {
                propsCopied[key] = computed(() => [ ...props[key] ])
            } else if (typeof value === 'object') {
                propsCopied[key] = computed(() => ({ ...props[key] }))
            } else {
                propsCopied[key] = computed(() => props[key])
            }
        }
        return propsCopied
    },
}
