import { defineAsyncComponent } from 'vue'

export default {
    install(app) {
        const components = import.meta.glob('@/Components/Base/**/*.vue', { eager: true, })
        Object.entries(components).forEach(([path, definition]) => {
            const componentName = path
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
            app.component(componentName, definition.default)
        })
    },
}

