<script setup>
import { computed, reactive, ref, onMounted, } from 'vue'
import AppUtils from '@/Functions/AppUtils'
import { useForm } from '@inertiajs/vue3'

//////////////////////////////////////////////////
// props -> data
const props = defineProps({
    label: String,
    errors: {
        type: [ String, Array, ],
        default(rawProps) {
            return '';
        },
    },
    modelValue: {
        type: [ String, null, ],
        required: true,
        default(rawProps) {
            return '';
        },
    },
    type: { type: String, default: 'text', },
    required: { type: Boolean, default: false, },
})
const data = AppUtils.oneWayProps(props)
data.modelValue = computed(() => {
    if (!props['modelValue']) {
        return ''
    }
    return props['modelValue']
})
data.errors = computed(() => {
    if (Array.isArray(props['errors'])) {
        return [ ...props['errors'] ]
    } else {
        return [ props['errors'] ]
    }
})

//////////////////////////////////////////////////
// other variables
const form = useForm({
})
const vars = {
}

//////////////////////////////////////////////////
// lifecycle
onMounted(async () => {
})

//////////////////////////////////////////////////
// methods

</script>

<template>
    <div
        class="fjs-form-field fjs-form-field-textfield"
        :class="{ 'fjs-has-errors': errors, }"
        >
        <label class="fjs-form-field-label">
            {{ label }}
            <span v-if="required" class="fjs-asterix">*</span>
        </label>
        <div class="fjs-input-group">
            <input type="text" class="fjs-input" :value="data.modelValue.value" @input="$emit('update:modelValue', $event.target.value)">
        </div>
        <div class="fjs-form-field-error">
            <ul>
                <li v-for="error in data.errors.value">{{ error }}</li>
            </ul>
        </div>
    </div>
</template>

